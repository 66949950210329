import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const useRules = () => {
  const { t } = useI18n();
  const rules = computed(() => ({
    required:
      (errorMsg = t('validations.fieldRequired')) =>
      (v) =>
        !!v || errorMsg,
    email:
      (errorMsg = t('validations.emailValid')) =>
      (v) =>
        /.+@.+\..+/.test(v) || errorMsg,
  }));
  return rules;
};

export default useRules;
