<template>
  <v-btn v-if="isSuperAdmin" color="primary" style="margin-top: 5px; margin-left: -8px" size="30">
    <v-icon class="unfold-btn" icon="mdi-unfold-more-horizontal"></v-icon>
    <v-menu activator="parent" max-width="300">
      <v-list>
        <v-list-subheader color="black">{{ t('global.organizations') }}</v-list-subheader>

        <v-list-item
          v-for="(item, i) in organizationItems"
          :key="`org-switcher-${i}`"
          :value="`org-switcher-${i}`"
          color="primary"
          variant="plain"
          style="margin-left: 10px"
          @click="selectOrganization(item.id)">
          <template #append>
            <v-icon
              v-if="item.id === activeOrganizationId"
              :icon="'mdi-check'"
              size="small"></v-icon>
          </template>

          <v-list-item-title>{{ item.name }}</v-list-item-title>
        </v-list-item>

        <v-list-subheader color="black">{{ t('global.personalAccount') }}</v-list-subheader>

        <v-list-item
          key="org-switcher-personal"
          value="org-switcher-personal"
          color="primary"
          variant="plain"
          style="margin-left: 10px"
          @click="selectUser">
          <template #append>
            <v-icon v-if="activeUserUid" :icon="'mdi-check'" size="small"></v-icon>
          </template>
          <v-list-item-title>{{ displayName }}</v-list-item-title>
        </v-list-item>

        <v-list-subheader v-if="isSuperAdmin" color="black">Super Admin</v-list-subheader>

        <RouterLink class="link" :to="{ name: 'Organizations' }">
          <v-list-item
            v-if="isSuperAdmin"
            key="org-switcher-orgs"
            value="org-switcher-orgs"
            color="primary"
            variant="plain">
            <template #prepend>
              <v-icon :icon="'mdi-office-building'" style="margin-right: -24px"></v-icon>
            </template>
            <v-list-item-title>
              {{ t('global.organizations') }}
            </v-list-item-title>
          </v-list-item>
        </RouterLink>

        <RouterLink class="link" :to="{ name: 'Users' }">
          <v-list-item
            v-if="isSuperAdmin"
            key="org-switcher-users"
            value="org-switcher-users"
            color="primary"
            variant="plain">
            <template #prepend>
              <v-icon :icon="'mdi-account-multiple'" style="margin-right: -24px"></v-icon>
            </template>
            <v-list-item-title>
              {{ t('global.users') }}
            </v-list-item-title>
          </v-list-item>
        </RouterLink>
      </v-list>
    </v-menu>
  </v-btn>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

const { t } = useI18n();

const store = useStore();
const router = useRouter();

const isSuperAdmin = computed(() => store.getters.isSuperAdmin);
const displayName = computed(() => store.getters.displayName);

const organizationItems = computed(() => store.state.organizations);
const activeOrganizationId = computed(() => store.state.organization?.id);
const activeUserUid = computed(() => !store.state.organization && store.state.userProfile?.uid);

const selectOrganization = (organizationId) => {
  store.dispatch('selectOrganizationId', { organizationId });
  router.push('/');
  store.dispatch('newChat');
};

const selectUser = () => {
  store.dispatch('selectUser');
  router.push('/');
  store.dispatch('newChat');
};
</script>

<style lang="scss" scoped>
.unfold-btn {
  text-transform: none;
}
.link {
  text-decoration: none;
  color: inherit;
}
</style>
