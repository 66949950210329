const PRODUCTION =
  process.env.VUE_APP_ENV === 'production' || process.env.NODE_ENV === 'production';

const DEV = process.env.VUE_APP_ENV === 'dev';

export const config = {
  apiBaseUrl: DEV
    ? 'https://api-cloud-run-dev-dybvjoi3xa-ez.a.run.app'
    : PRODUCTION
    ? 'https://api-cloud-run-production-dybvjoi3xa-ez.a.run.app'
    : 'http://localhost:3000',
};

config.graphqlUrl = `${config.apiBaseUrl}/graphql`;
config.graphqlUrlPublic = `${config.apiBaseUrl}/public/graphql`;

export const page_size = 100;
export const fuzziness = 1;
