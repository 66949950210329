import { createStore } from 'vuex';

import { v4 as uuidv4 } from 'uuid';

import * as userManagement from './modules/userManagement';

// BROWSE
import * as browseHelpers from './modules/browseHelpers';

// CHAT
import * as chatHelpers from './modules/chatHelpers';

// BLOG
import * as blogHelpers from './modules/blogHelpers';

// PADDLE
import * as paddleHelpers from './modules/paddleHelpers';

// MIXPANEL
import * as userTracking from './modules/userTracking';
import { defaultSearchTypes } from '@/utils/browse';
import { subscriptionStatusOptions } from '@/utils/paddle';

function initialState() {
  return {
    customHeader: false,
    sideBanner: false,
    mobile: false,
    mobileSideMenu: false,
    contactModal: false,
    enterpriseContactModal: false,

    chatDocs: [],
    chatHistory: [],
    chatAnswerParts: [],
    chatStandaloneQuestion: '',
    chatLoading: false,
    searchLoading: false,
    questionLoading: false,
    chatLoadingDocs: false,
    chatLoadingQuery: false,
    chatSearchQuery: '',
    chatNewMessage: '',
    chatMessages: [
      {
        user: 'ASSISTANT',
        content: '',
        responseQuality: 0,
        feedback: '',
      },
    ],
    chatCitations: [], // other references
    chatCitationsMenuOpened: false,
    chatUsedCitations: [], // used references that are linked to the chat
    // chatPdfPath: "",
    chatUsePubmed: true,
    chatPdfPath:
      'https://storage.googleapis.com/library-docs/003f7406-c856-458a-9a45-f8a93256792f.pdf',
    chatPdfPage: 1,
    chatPubmedDoc: {},
    chatDocType: 'pdf',
    chatSelectedCitation: null,
    chatPdfSource: '',
    chatSessionId: uuidv4(),
    chatCurrentPageHistory: 1,
    chatItemsPerPageHistory: 5,
    chatRandomUserId: uuidv4(),
    chatMainQuestion: '',
    chatSourceLanguage: 'en',
    chatLayman: false,
    chatCopySuccess: {},
    chatAskEmail: false,
    chatAskFeedback: false,
    chatFeedback: '',
    chatFeedbackQuestion: null,
    chatDeletingBusy: {},
    chatIsExpanded: false,
    chatWelcomeMessage: '',
    chatUsedIds: [],
    chatUseSource: 'pubmed',
    chatFilters: {
      sinceYear: null,
      studyTypes: ['randomized_trial', 'meta_analysis', 'systematic_review', 'human_intervention'],
      impactFactor: false,
      sampleSize: undefined,
    },
    chatGptMode: localStorage.getItem('chatGptMode')
      ? localStorage.getItem('chatGptMode') === 'true'
      : true,
    chatElaborateMode: localStorage.getItem('chatElaborateMode')
      ? localStorage.getItem('chatElaborateMode') === 'true'
      : true,
    isPaddlePaymenCancelationInProgress: false,
    chatMobileSection: null,
    chatSelectedSourcesInternational: [],
    chatSelectedSourcesRegional: [],
    chatSelectedSourcesCustom: [],
    chatToggleUsePubmed: true,
    chatFirstView: true,
    locale: localStorage.getItem('selectedLocale')
      ? localStorage.getItem('selectedLocale') === 'nl'
        ? 'nl'
        : 'en'
      : navigator.language.toLowerCase().includes('nl')
      ? 'nl'
      : 'en',
    isUserLoading: false,
    userProfile: {},
    isUserVerified: false,
    loading: true,
    docs: [],
    initDocs: [],
    showRecent: true, // also shows whether a search has been initiated
    summary: '',
    initSummary: '',
    searchTab: 0,
    searchMobileSection: null,
    changedSearchTabFrom: null, // use to store the tab we will return to when click "back to results
    numDocs: 0,
    initNumDocs: 0,
    numPages: 0,
    initNumPages: 0,
    queries: {},
    uniqueDisciplines: [],
    current_pubmed: '',
    current_qids: [],
    current_pico: {
      population_must: '',
      intervention_must: '',
      population_at_least: '',
      intervention_at_least: '',
      population_must_not: '',
      intervention_must_not: '',
    },
    sort_order: '',
    article_type_list: ['randomized_trial'],
    totalNumberDocs: 0,
    currentWeek: true,

    // mutated and called by multiple components
    s_is_shown: false,
    s_search_current_week: false,
    s_sort_by: 'impact_factor',
    s_sort_options: [
      { key: 'ml_n', labelKey: 'search.sortOptions.sampleSize' },
      { key: 'publication_date', labelKey: 'search.sortOptions.recency' },
      { key: 'impact_factor', labelKey: 'search.sortOptions.impactFactor' },
    ],
    s_sort_order_options: [
      { label: 'ascending', key: 'asc' },
      { label: 'descending', key: 'desc' },
    ],
    s_sort_order: 'desc',
    s_page: 1,
    s_fuzziness: 1,
    s_article_type: defaultSearchTypes,
    s_article_options: [
      {
        labelKey: 'search.studyTypes.randomizedTrial',
        key: 'randomized_trial',
      },
      { labelKey: 'search.studyTypes.metaAnalysis', key: 'meta_analysis' },
      {
        labelKey: 'search.studyTypes.systematicReview',
        key: 'systematic_review',
      },
      {
        labelKey: 'search.studyTypes.clinicalIntervention',
        key: 'human_intervention',
      },
      { labelKey: 'search.studyTypes.rodentStudy', key: 'rodent' },
      { labelKey: 'search.studyTypes.protocol', key: 'protocol' },
      { labelKey: 'search.studyTypes.other', key: 'misc' },
    ],
    s_search_type: 'pico',
    s_simple: true,
    email: '',
    title: '',
    institution: '',

    blog_list: null,
    blog_article: null,
    monthPrice: 0,
    annualyPrice: 0,
    monthCurrencyCode: '',
    annualyCurrencyCode: '',
    auth_redirected: false,
    paddleTransactionId: '',
    trial_interval_month: null,
    trial_frequency_month: null,
    trial_interval_annual: null,
    trial_frequency_annual: null,
    paddlePaymentInProgress: false,
    userForVerificationLink: {},
    isVerificationFromLogin: false,
    discount: {},
    discountAmount: '',
    // Free pro usage
    currentWeekProUsage: 0,
    maxWeekProUsage: 5,
    // Organization related
    organizations: [],
    organizationSources: [],
    organization: null,
  };
}

export default createStore({
  state: initialState,
  getters: {
    isLoggedIn: (state) => Boolean(state.userProfile && state.userProfile.uid),
    getSourcesCount: (state, getters) => getters.chatSelectedSources.length + state.chatUsePubmed,
    isNewChat: (state) => state.chatMessages.length === 1,
    remainingWeekProUsage: (state) => {
      const remaining = state.maxWeekProUsage - state.currentWeekProUsage;
      return remaining < 0 ? 0 : remaining;
    },
    isPayingUser: (state) => {
      if (!state.userProfile?.uid) return false;
      const { subscriptionStatus } = state.userProfile;
      return [...subscriptionStatusOptions.active, ...subscriptionStatusOptions.trialing].includes(
        subscriptionStatus
      );
    },
    isPaying: (state) => {
      if (!state.userProfile?.uid) return false;
      if (state.userProfile?.sandbox) {
        return true;
      } else {
        const { subscriptionStatus, organizationRoles } = state.userProfile;
        // TODO: only use subscriptionStatus from active organization role
        return (
          [...subscriptionStatusOptions.active, ...subscriptionStatusOptions.trialing].includes(
            subscriptionStatus
          ) ||
          (organizationRoles || []).filter(
            (role) => role.organization?.subscriptionStatus === 'active'
          ).length > 0
        );
      }
    },
    isPro: (state, getters) => {
      if (!state.userProfile?.uid) return false;
      if (state.userProfile?.sandbox) {
        return true;
      } else {
        const paying = getters.isPaying;
        const remainingProUsage = state.maxWeekProUsage - state.currentWeekProUsage;
        return paying || remainingProUsage > 0;
      }
    },
    isSandbox: (state) => {
      if (!state.userProfile) return false;
      const sandbox = state.userProfile?.sandbox;
      const organization = state.organization;
      return !organization && sandbox;
    },
    isSuperAdmin: (state) => {
      if (!state.userProfile) return false;
      return state.userProfile?.superAdmin;
    },
    displayName: (state) => {
      if (!state.userProfile) return '';
      const { email, firstName, lastName } = state.userProfile;
      if (firstName && lastName) return `${firstName} ${lastName}`;
      if (firstName) return firstName;
      if (lastName) return lastName;
      return email;
    },
    chatSelectedSources: (state) => [
      ...state.chatSelectedSourcesInternational,
      ...state.chatSelectedSourcesRegional,
      ...state.chatSelectedSourcesCustom,
    ],
    getDocs: (state) => (state.showRecent ? state.initDocs : state.docs),
    getNumDocs: (state) => (state.showRecent ? state.initNumDocs : state.numDocs),
    getNumPages: (state) => (state.showRecent ? state.initNumPages : state.numPages),
    getSummary: (state) => (state.showRecent ? state.initSummary : state.summary),
  },
  mutations: {
    SET_STATE_PROPERTY(state, { property, value }) {
      if (Object.prototype.hasOwnProperty.call(state, property)) {
        state[property] = value;
      }
    },
    SET_CHAT_FILTER(state, { property, value }) {
      if (Object.prototype.hasOwnProperty.call(state.chatFilters, property)) {
        state.chatFilters[property] = value;
      }
    },
    SET_INIT_DOCS(state) {
      this.commit('SET_STATE_PROPERTY', { property: 'initDocs', value: [...state.docs] });
      this.commit('SET_STATE_PROPERTY', { property: 'initSummary', value: state.summary });
      this.commit('SET_STATE_PROPERTY', { property: 'initNumPages', value: state.numPages });
      this.commit('SET_STATE_PROPERTY', { property: 'initNumDocs', value: state.numDocs });
    },
    USE_INIT_DOCS(state) {
      this.commit('SET_STATE_PROPERTY', {
        property: 'docs',
        value: [...state.initDocs].map((doc) => ({ ...doc, selected: false })),
      });
      this.commit('SET_STATE_PROPERTY', { property: 'summary', value: state.initSummary });
      this.commit('SET_STATE_PROPERTY', { property: 'numPages', value: state.initNumPages });
      this.commit('SET_STATE_PROPERTY', { property: 'numDocs', value: state.initNumDocs });
    },
    RESET(state) {
      // acquire initial state
      const s = initialState();
      Object.keys(s).forEach((key) => {
        state[key] = s[key];
      });
    },
  },
  actions: {
    ...userManagement,
    ...browseHelpers,
    ...chatHelpers,
    ...blogHelpers,
    ...paddleHelpers,
    ...userTracking,
  },
});
